import { useEffect, useRef, useState } from 'react'
import '../css/aboutApp.css'
import Marquee from 'react-fast-marquee'
import Skeleton from 'react-loading-skeleton'
import { imageURL } from '../api/api'



const AboutApp = ({homeContent}) => {
    const nameRef = useRef(null)
    const emailRef = useRef(null)
    const whats = useRef(null)
    const [size, setSize] = useState(window.innerWidth)
    const [slides, setSlides] = useState([])
    const [phones, setPhones] = useState([
        {
            text: 'Acompanhe a localização dos ônibus em tempo real e nunca mais perca um',
            image: process.env.PUBLIC_URL + '/images/screenshot1.png'
        },
        {
            text: 'Acesse detalhes completos sobre linhas, itinerários e horário',
            image: process.env.PUBLIC_URL + '/images/screenshot2.png'
        },
        {
            text: 'Explore rotas e paradas em mapas interativos com zoom e detalhes',
            image: process.env.PUBLIC_URL + '/images/screenshot3.png'
        },
        {
            text: 'Adquira seus tickets diretamente pelo aplicativo de forma rápida e segura',
            image: process.env.PUBLIC_URL + '/images/screenshot4.png'
        },
        {
            text: 'Segurança garantida em todas as suas transações financeiras',
            image: process.env.PUBLIC_URL + '/images/screenshot5.png'
        },
        {
            text: 'Receba ofertas especiais apenas para quem utiliza o aplicativo',
            image: process.env.PUBLIC_URL + '/images/screenshot5.png'
        }
    ])

    return (
        <div className='homeSection' id='aboutApp' style={{ marginTop: 70, width: '100%', maxWidth: '100vw', padding: 0}}>
            <div className='description'>Explore o nosso</div>
            <div className='title'>Aplicativo AngoTrans</div>

            <Marquee direction='right' speed={50} gradient gradientColor='#c9af9100' gradientWidth={size > 500 ? size /500 * 50 : 30} pauseOnClick pauseOnHover={false} style={{
                    display: 'flex',
                    
                }}>
                    <div className='phones'>
                {homeContent?.iframes?.filter((i)=> i?.status == 'activo')?.length >= 4 ? homeContent?.iframes?.filter((i)=> i?.status == 'activo').map((item) => {
                    console.log('item', item)
                    return (
                        <div className='phoneContainer'>
                        <div className='text'>{item?.text||item?.message}
                        </div>
                        <div class="phone">
                            <div class="notch"></div>
                            <img src={`${imageURL}/${item?.imagem}`}/>
                        </div>
                    </div>
                    
                    )
                })
            :
            phones.map((item) => {
                return (
                    <div className='phoneContainer'>
                    {/* <div className='text' style={{opacity: 0}}>{item?.text}
                    </div> */}
                    <Skeleton className='text' count={2} style={{height: 15, marginBlock: 10}}/>
                    <div class="phone">
                        <div class="notch"></div>
                        {/* <img src={item?.image}/> */}
                    </div>
                </div>
                
                )
            })
            }
                    </div>
                </Marquee>
        </div>
    )
}

  
export default AboutApp